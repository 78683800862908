import { reactive, watch } from "vue";

interface FileAttachment {
  url: string;
  name: string;
}

interface EmailPopupState {
  open: boolean;
  minimized: boolean;
  maximized: boolean;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  fileUrls: FileAttachment[];
  lots: Lot[];
  parentNomProgrammeP: string;
  parentNumeroLotP: string;
}

export const emailState = reactive<EmailPopupState>({
  open: false,
  minimized: false,
  maximized: true,
  to: [],
  cc: [],
  bcc: [],
  subject: "",
  body: "",
  fileUrls: [],
  lots: [],
  parentNomProgrammeP: "",
  parentNumeroLotP: "",
});

watch(
  emailState,
  () => {
    sessionStorage.setItem("emailPopupState", JSON.stringify(emailState));
  },
  { deep: true }
);

export const loadEmailState = () => {
  const savedState = sessionStorage.getItem("emailPopupState");
  if (savedState) {
    Object.assign(emailState, JSON.parse(savedState));
  }
};

export const toggleEmailPopup = () => {
  emailState.open = !emailState.open;
};

export const emailPopupOpen = () => {
  emailState.open = true;
  maximizePopup();
};

export const emailPopupClose = () => {
  emailState.open = false;
  clearEmail();
};

export const minimizePopup = () => {
  emailState.minimized = true;
  emailState.maximized = false;
};

export const maximizePopup = () => {
  emailState.maximized = true;
  emailState.minimized = false;
};

export const updateEmailField = (field: keyof EmailPopupState, value: any) => {
  emailState[field] = value;
};

export const addFileAttachment = (fileUrl: string, fileName: string) => {
  emailState.fileUrls.push({ url: fileUrl, name: fileName });
};

export const removeFileAttachment = (index: number) => {
  emailState.fileUrls.splice(index, 1);
};

export const clearEmail = () => {
  emailState.to = [];
  emailState.cc = [];
  emailState.bcc = [];
  emailState.subject = "";
  emailState.body = "";
  emailState.fileUrls = [];
  emailState.lots = [];
  emailState.parentNomProgrammeP = "";
  emailState.parentNumeroLotP = "";
};

export const setEmailTemplate = (lot: object, message: string) => {
  if (lot && message) {
    const replacements = {
      Nom_promoteur: lot.program.program_name,
      Nom_Programme: lot.program.program_name,
      Numero_lot: lot.lot_id_program,
      Ville: lot.program.f_city_name_postal_code.split("-")[0],
      Typologie: lot.typology,
      "Prix_de_vente_TVA_5.5%": lot.price_tva55 ? lot.price_tva55 + " €" : "-",
      "Prix_de_vente_TVA_20%": lot.price_tva20 ? lot.price_tva20 + " €" : "-",
    };

    let updatedMessage = message;

    if (emailState.parentNumeroLotP) {
      const newP =
        emailState.parentNumeroLotP +
        `<span class="replace ${lot.program.id}"></span><span class="new-replace"></span>`;
      updatedMessage = updatedMessage.replace(
        emailState.parentNumeroLotP,
        newP
      );
    }

    for (const [placeholder, replacement] of Object.entries(replacements)) {
      const regex = new RegExp(
        `<span class="email-template-var-text"[^>]*>${placeholder}</span>`,
        "g"
      );
      updatedMessage = updatedMessage.replace(regex, replacement);
    }
    updateEmailField("body", updatedMessage);
    updateEmailField("lots", [lot]);
  }
};

export const addLotTOEmailTemplate = (lot: object) => {
  if (lot) {
    const replacements = {
      Nom_promoteur: lot.program.program_name,
      Nom_Programme: lot.program.program_name,
      Numero_lot: lot.lot_id_program,
      Ville: lot.program.f_city_name_postal_code.split("-")[0],
      Typologie: lot.typology,
      "Prix_de_vente_TVA_5.5%": lot.price_tva55 ? lot.price_tva55 + " €" : "-",
      "Prix_de_vente_TVA_20%": lot.price_tva20 ? lot.price_tva20 + " €" : "-",
    };

    let updatedMessage = emailState.body;

    if (emailState.parentNumeroLotP) {
      let newP =
        emailState.parentNumeroLotP +
        `<span class="replace ${lot.program.id}"></span>`;

      if (
        updatedMessage.includes(
          `<span class="replace ${lot.program.id}"></span>`
        )
      ) {
        updatedMessage = updatedMessage.replace(
          `<span class="replace ${lot.program.id}"></span>`,
          newP
        );
      } else {
        newP =
          emailState.parentNomProgrammeP +
          emailState.parentNumeroLotP +
          `<span class="new-replace"></span>`;
        updatedMessage = updatedMessage.replace(
          `<span class="new-replace"></span>`,
          newP
        );
      }
    }
    for (const [placeholder, replacement] of Object.entries(replacements)) {
      const regex = new RegExp(
        `<span class="email-template-var-text"[^>]*>${placeholder}</span>`,
        "g"
      );
      updatedMessage = updatedMessage.replace(regex, replacement);
    }
    updateEmailField("body", updatedMessage);
    updateEmailField("lots", [...emailState.lots, lot]);
  }
};
